import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/popup/Popup.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const LegacyPatternLink = makeShortcode("LegacyPatternLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/popup" storybook="https://workday.github.io/canvas-kit/?path=/story/components-popups-popup--basic" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/b6bf72a3dbf09521bd9147f3344bafa2/536c7/component-anatomy-popup.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "43.513513513513516%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a pop container with annotation markers.",
              "title": "Image of a pop container with annotation markers.",
              "src": "/static/b6bf72a3dbf09521bd9147f3344bafa2/50383/component-anatomy-popup.png",
              "srcSet": ["/static/b6bf72a3dbf09521bd9147f3344bafa2/1efb2/component-anatomy-popup.png 370w", "/static/b6bf72a3dbf09521bd9147f3344bafa2/50383/component-anatomy-popup.png 740w", "/static/b6bf72a3dbf09521bd9147f3344bafa2/536c7/component-anatomy-popup.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Title (Optional)`}</strong>{`: Titles should display the title of the content or dialog.`}</li>
        <li parentName="ol"><strong parentName="li">{`Content`}</strong>{`: Popups contain different types of content. Typical types of content include alerts
and dialogs.`}</li>
        <li parentName="ol"><strong parentName="li">{`Buttons(Optional)`}</strong>{`: When there is a user action, use the action bar. When displaying
informational content, use in-line buttons.`}</li>
        <li parentName="ol"><strong parentName="li">{`Close “X” Icon (Optional)`}</strong>{`: Users are able to intentionally dismiss a popup.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <p>{`Popup components are generally used in place of Non-Modal Dialogs. Because Non-Modal Dialogs only
minimally obstruct the page, they are ideal for drawing attention to optional, non-critical
information or new features while keeping page content still visible. Popups appear within the
context of a page and do not interrupt normal workflow.`}</p>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to use`}</h3>
      <ul>
        <li parentName="ul">{`Use Popups when needing to customize a popup element beyond the offerings of other popup
components such as a Modal, Tooltip, etc.`}</li>
        <li parentName="ul">{`Do make Popups easily dismissible in context of the trigger element.`}</li>
        <li parentName="ul">{`The popup component is used to display content that doesn’t fit the use cases of more specific
notification components such as Tooltips, Modals, Dropdown menus, etc.`}</li>
        <li parentName="ul">{`Popups can be used to display confirmation messages, validate user inputs, or display short
informational content in the context of a user action.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`Do not use Popups to display dense information, such as Tables or Multi-View Containers.`}</li>
        <li parentName="ul">{`Popups are easy to dismiss. Consider using a `}<a parentName="li" {...{
            "href": "/components/popups/modal"
          }}>{`Modal`}</a>{` if you require more
user attention or interactive form components in your popup.`}</li>
        <li parentName="ul">{`Consider a `}<a parentName="li" {...{
            "href": "/components/popups/toast"
          }}>{`Toast`}</a>{` if you are communicating status or confirmation of
the application process to the user.`}</li>
        <li parentName="ul">{`Consider a `}<a parentName="li" {...{
            "href": "/components/popups/menu"
          }}>{`Menu`}</a>{` if the input is a single selection of options.`}</li>
        <li parentName="ul">{`Use a `}<a parentName="li" {...{
            "href": "/components/popups/tooltip"
          }}>{`Tooltip`}</a>{` to add context a button, link, to other element.`}</li>
        <li parentName="ul">{`See `}<LegacyPatternLink href="/patterns/notifications/" mdxType="LegacyPatternLink">{`Notifications`}</LegacyPatternLink>{` and `}<LegacyPatternLink href="/patterns/errors-and-alerts/" mdxType="LegacyPatternLink">{`Errors and Alerts`}</LegacyPatternLink>{`
guidance in the Patterns section for more information on types of notifications and their use
cases.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`See the `}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/dialogs"
          }}>{`Dialogs, Popups, and Modals`}</a>{` page in the UI Text
section of the Content Style Guide for modal language guidelines.`}</li>
      </ul>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      